<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <AttachmentsPrintGrid
      :attachments="project.serviceRailOverlapAttachments"
      fieldName="serviceRailOverlapAttachments"
      fieldDescription="serviceRailOverlapAttachments"
      :maxImages="4"
    />

    <AttachmentsPrintGrid
      :attachments="project.serviceTrayfDistanceAttachments"
      fieldName="trayfDistanceAttachments"
      fieldDescription="trayfDistanceAttachments"
      :maxImages="4"
    />

    <AttachmentsPrintGrid
      :attachments="project.servicePanelBottomViewAttachments"
      fieldName="panelBottomViewAttachments"
      fieldDescription="panelBottomViewAttachments_description"
      :maxImages="4"
    />
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';
import { range } from '@/helpers/math.js';

export default {
  name: 'MeasurementPrintPage1',
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: null },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
  methods: { range },
};
</script>
