<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <AttachmentsPrintGrid
      :attachments="project.mtbxLabelSnAttachments"
      fieldName="mtbxLabelSnAttachments"
      :maxImages="2"
    />
    <AttachmentsPrintGrid
      :attachments="project.invLabelSnAttachments"
      fieldName="invLabelSnAttachments"
      :maxImages="2"
    />
    <AttachmentsPrintGrid
      :attachments="project.batLabelSnAttachments"
      fieldName="batLabelSnAttachments"
      :maxImages="2"
    />
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';
import { range } from '@/helpers/math.js';

export default {
  name: 'InstallationPrintPage',
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: null },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
  methods: { range },
};
</script>
