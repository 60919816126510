<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h2>10. {{ $t('wallbox') }}</h2>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('qcellsWallboxContractState') }}</div>
        <div>{{ $t(project.serviceReport.qcellsWallboxContractState) }}</div>
      </div>
    </div>

    <AttachmentsPrintGrid
      :attachments="project.serviceWallboxCablePathNotes"
      fieldName="serviceWallboxCablePathNotes"
      :maxImages="2"
    />

    <AttachmentsPrintGrid
      :attachments="project.serviceWallboxLocationAttachments"
      fieldName="serviceWallboxLocationAttachments"
      :maxImages="2"
    />

    <AttachmentsPrintGrid
      :attachments="project.serviceWallboxAccessCardAttachments"
      fieldName="serviceWallboxAccessCardAttachments"
      :maxImages="2"
    />

    <AttachmentsPrintGrid
      :attachments="project.serviceFiLsWallboxAttachments"
      fieldName="serviceFiLsWallboxAttachments"
      :maxImages="2"
    >
    </AttachmentsPrintGrid>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';

export default {
  name: 'WallboxPrintPage',

  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
};
</script>
