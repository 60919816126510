<template>
  <div v-if="project && pages" class="document">
    <BrickAndSubstructurePrintPage1 :pageNumber="currentPage()" :project="project" />
    <BrickAndSubstructurePrintPage2 :pageNumber="currentPage()" :project="project" />
    <SolarPanelsPrintPage1 :pageNumber="currentPage()" :project="project" />
    <SolarPanelsPrintPage2 :pageNumber="currentPage()" :project="project" />
    <CableManagementPrintPage1 :pageNumber="currentPage()" :project="project" />
    <HAkPrintPage1 :pageNumber="currentPage()" :project="project" />
    <ElectricMeterPrintPage1 :pageNumber="currentPage()" :project="project" />
    <ElectricMeterPrintPage2 :pageNumber="currentPage()" :project="project" />
    <InverterPrintPage1 :pageNumber="currentPage()" :project="project" />
    <!-- <InverterPrintPage2 :pageNumber="currentPage()" :project="project" /> -->
    <InternetAccessPrintPage :pageNumber="currentPage()" :project="project" />
    <InvBatMtbxPrintPage :pageNumber="currentPage()" :project="project" />
    <InvBatMtbxPrintPage2 :pageNumber="currentPage()" :project="project" />
    <EarthingUnitPrintPage :pageNumber="currentPage()" :project="project" />
    <WallboxPrintPage :pageNumber="currentPage()" :project="project" />
  </div>
  <div v-else>{{ $t('no_data_found') }}</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ExtendedProject } from '@/helpers/projects';
import BrickAndSubstructurePrintPage1 from '@/views/Assessment/BrickAndSubstructurePrintPage1';
import BrickAndSubstructurePrintPage2 from '@/views/Assessment/BrickAndSubstructurePrintPage2';
import SolarPanelsPrintPage1 from '@/views/Assessment/SolarPanelsPrintPage1';
import SolarPanelsPrintPage2 from '@/views/Assessment/SolarPanelsPrintPage2';
import CableManagementPrintPage1 from '@/views/Assessment/CableManagementPrintPage1';
import HAkPrintPage1 from '@/views/Assessment/HAkPrintPage1';
import ElectricMeterPrintPage1 from '@/views/Assessment/ElectricMeterPrintPage1';
import ElectricMeterPrintPage2 from '@/views/Assessment/ElectricMeterPrintPage2';
import InverterPrintPage1 from '@/views/Assessment/InverterPrintPage1';
import InvBatMtbxPrintPage from '@/views/Assessment/InvBatMtbxPrintPage';
import InvBatMtbxPrintPage2 from '@/views/Assessment/InvBatMtbxPrintPage2';
import InternetAccessPrintPage from '@/views/Assessment/InternetAccessPrintPage';
import EarthingUnitPrintPage from '@/views/Assessment/EarthingUnitPrintPage';
import WallboxPrintPage from '@/views/Assessment/WallboxPrintPage';

export default {
  components: {
    BrickAndSubstructurePrintPage1,
    BrickAndSubstructurePrintPage2,
    SolarPanelsPrintPage1,
    SolarPanelsPrintPage2,
    CableManagementPrintPage1,
    HAkPrintPage1,
    ElectricMeterPrintPage1,
    ElectricMeterPrintPage2,
    InverterPrintPage1,
    // InverterPrintPage2,
    InvBatMtbxPrintPage,
    InvBatMtbxPrintPage2,
    InternetAccessPrintPage,
    EarthingUnitPrintPage,
    WallboxPrintPage,
  },
  data() {
    return {};
  },
  props: {
    projectNumber: {
      required: true,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['getProjectByNumber']),
    pages() {
      return 1;
    },
    project() {
      const project = this.getProjectByNumber(this.number);
      if (project) {
        new ExtendedProject(project);
      }
      return project;
    },
    isFlatRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'FLAT_ROOF';
    },
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },
  methods: {
    ...mapActions(['fetchProjectByNumber']),
    currentPage() {
      this.pageCounter += 1;
      return this.pageCounter;
    },
  },
  async mounted() {
    this.number = this.projectNumber;
    await this.fetchProjectByNumber(this.number);
  },
  created() {
    this.pageCounter = 0; // create non reactive data property
  },
};
</script>
<style src="./AssessmentPrintPage.scss" lang="scss" />
<style />
